const CounterData = [
  {
    count: "40000+",
    des: "Verified Reviews",
  },
  {
    count: "110000+",
    des: "Companies",
  },
  {
    count: "2000+",
    des: "Clients Registered",
  },
  {
    count: "1000+",
    des: "Promoted Company",
  },
];

export default CounterData;
