var costs = [
  {
    value: "100-200 $",
    label: "100-200 $",
  },
  {
    value: "200-300 $",
    label: "200-300 $",
  },
  {
    value: "300-500 $",
    label: "300-500 $",
  },
];

export { costs };
