var duration = [
  {
    value: "7 days",
    label: "7 days",
  },
  {
    value: "15 days",
    label: "15 days",
  },
  {
    value: "30 days",
    label: "30 days",
  },
];

export { duration };
